import * as types from './mutation-types'
import FirebaseRepository from '@/shared/firebase/firebase-repository'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import store from './index'

const state = () => {
  return {
    darkMode: false,
    currentUserDarkMode: false
  }
}

const getters = {
  darkMode: (state) => state.darkMode,
  currentUserDarkMode: state => state.currentUserDarkMode
}
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode
  },
  SET_CURRENT_USER_MODE(state, payload) {
    state.currentUserDarkMode = payload
  }
}
const actions = {
  setDarkMode({ commit }, darkMode) {
    const currentUser = store.getters['auth/currentUser']
    if (currentUser && currentUser.id) {
      firebase.firestore().collection('users').doc(currentUser.id).update({ darkMode })
      localStorage.setItem('darkMode', darkMode)
    }
    commit(types.SET_DARK_MODE, { darkMode })
  },
  async findCurrentUserMode({ commit }) {
    let darkMode = false
    const currentUser = store.getters['auth/currentUser']
    if (currentUser && currentUser.id) {
      const user = await FirebaseRepository.findDocument('users', currentUser.id)
      darkMode = user ? !!user.darkMode : false
    }
    commit('SET_CURRENT_USER_MODE', darkMode)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
