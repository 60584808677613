<template>
  <div v-if="loading">
    <div id="overlay">
      <div class="flex items-center justify-center h-full">
        <half-circle-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'half-circle'" />
        <flower-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'flower'" />
        <orbit-spinner :animation-duration="duration"  :size="size" :color="color" v-if="type == 'orbit'" />
        <radar-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'radar'" />
        <intersecting-circles-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'intersecting-circles'" />
        <scaling-squares-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'scaling-squares'" />
        <trinity-rings-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'trinity-rings'" />
        <fulfilling-square-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'fulfilling-square'" />
        <semipolar-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'semipolar'" />
        <self-building-square-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'self-building-square'" />
        <swapping-squares-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'self-building-square'" />
        <fulfilling-bouncing-circle-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'swapping-squares'" />
        <fingerprint-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'fingerprint'" />
        <spring-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'spring'" />
        <atom-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'atom'" />
        <breeding-rhombus-spinner :animation-duration="duration" :size="size" :color="color" v-if="type == 'breeding-rhombus'" />
        <looping-rhombuses-spinner :animation-duration="duration" :rhombus-size="15" :color="color" v-if="type == 'looping-rhombuses'" />
        <circles-to-rhombuses-spinner :animation-duration="duration" :circles-num="3" :circle-size="15" :color="color" v-if="type == 'circles-to-rhombuses'" />
        <pixel-spinner :animation-duration="duration" :pixel-size="70" :color="color" v-if="type == 'pixel'" />
        <hollow-dots-spinner :animation-duration="duration" :dot-size="15" :dots-num="3" :color="color" v-if="type == 'hollow-dots'" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import {
  HalfCircleSpinner,
  FlowerSpinner,
  OrbitSpinner,
  RadarSpinner,
  IntersectingCirclesSpinner,
  ScalingSquaresSpinner,
  TrinityRingsSpinner,
  FulfillingSquareSpinner,
  SemipolarSpinner,
  SelfBuildingSquareSpinner,
  SwappingSquaresSpinner,
  FulfillingBouncingCircleSpinner,
  FingerprintSpinner,
  SpringSpinner,
  AtomSpinner,
  BreedingRhombusSpinner,
  LoopingRhombusesSpinner,
  CirclesToRhombusesSpinner,
  PixelSpinner,
  HollowDotsSpinner
} from 'epic-spinners'

const SpinnerTypes = [
  'half-circle', 
  'flower', 
  'orbit', 
  'radar', 
  'intersecting-circles',
  'scaling-squares', 
  'trinity-rings', 
  'trinity-rings', 
  'fulfilling-square', 
  'semipolar',
  'self-building-square', 
  'swapping-squares', 
  'fulfilling-bouncing-circl', 
  'fingerprint', 
  'spring',
  'atom',
  'breeding-rhombus', 
  'looping-rhombuses', 
  'circles-to-rhombuses', 
  'pixel'
]

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'half-circle',
      required: true,
      validator(value) {
        // The value must match one of these strings
        const isValidType = SpinnerTypes.includes(value)
        if (!isValidType) console.error('The value of spinner type must match one of these strings', SpinnerTypes)
        return isValidType
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 1000
    },
    size: {
      type: Number,
      default: 60
    },
    color: {
      type: String,
      default: '#4469A8'
    }
  },

  components: {
    HalfCircleSpinner,
    FlowerSpinner,
    OrbitSpinner,
    RadarSpinner,
    IntersectingCirclesSpinner,
    ScalingSquaresSpinner,
    TrinityRingsSpinner,
    FulfillingSquareSpinner,
    SemipolarSpinner,
    SelfBuildingSquareSpinner,
    SwappingSquaresSpinner,
    FulfillingBouncingCircleSpinner,
    FingerprintSpinner,
    SpringSpinner,
    AtomSpinner,
    BreedingRhombusSpinner,
    LoopingRhombusesSpinner,
    CirclesToRhombusesSpinner,
    PixelSpinner,
    HollowDotsSpinner
  },
  setup(props) {
    const store = useStore()
    const iconColor = computed(() =>
      !store.state.main.darkMode ? props.color : '#ffffff'
    )

    return {
      iconColor
    }
  }
})
</script>

<style scoped>
#overlay {
  position: absolute; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.6); /* Black background with opacity */
  z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
  box-sizing: inherit;
  cursor: pointer; /* Add a pointer on hover */
}
</style>
