/* eslint-disable */     
// import CryptoJSON from 'crypto-json'
// import CryptoJS = require('crypto-js')

// const CryptoJSON = require('crypto-json')
// const CryptoJS = require('crypto-js')


/**
 * Encryption Functions
 */
export default class Crypto {
  // #region [ Encryption Functions ]
  // ================================================================= //
  //                     ENCRYPT & DECRYPT OBJECT                      //
  // ================================================================= //
  static encryptObject(object, key) {
    const CryptoJSON = require('crypto-json')
    const algorithm = 'aes256'
    const encoding = 'hex'
    const password = key
    let keys = []

    for (let [k, value] of Object.entries(object)) {
      if (value) {
        keys.push(k)
      }
    }
    const output = CryptoJSON.encrypt(object, password, {
      encoding,
      keys,
      algorithm
    })
    return output
  }

  static decryptObject(encryptedObject, key) {
    const CryptoJSON = require('crypto-json')
    const algorithm = 'aes256'
    const encoding = 'hex'
    const password = key
    let keys = []

    for (let [k, value] of Object.entries(encryptedObject)) {
      if (value) {
        keys.push(k)
      }
    }

    const output = CryptoJSON.decrypt(encryptedObject, password, {
      encoding,
      keys,
      algorithm
    })
    return output
  }

  // ================================================================= //
  //                     ENCRYPT & DECRYPT STRING                      //
  // ================================================================= //
  static encryptString(message, key) {
    const CryptoJS = require('crypto-js')
    const ciphertext = CryptoJS.AES.encrypt(message, key) // Encrypt
    return ciphertext
  }

  static decryptString(ciphertext, key) {
    if (!ciphertext) return null
    const CryptoJS = require('crypto-js')
    const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), key) // Decrypt
    const plaintext = bytes.toString(CryptoJS.enc.Utf8)
    return plaintext
  }
  
  // ================================================================= //
  //             OBJECT ENCRYPTION & DECRYPTION OTHER WAY              //
  // ================================================================= //
  static encryption(data, key) {
    const CryptoJS = require('crypto-js')
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key) // Encrypt
    return ciphertext
  }
  static decryption(cipherData, key) {
    if (!cipherData) return null
    const CryptoJS = require('crypto-js')
    const bytes = CryptoJS.AES.decrypt(cipherData.toString(), key) // Decrypt
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  }
  // #endregion
}