/* eslint-disable */

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export class AuthToken {
  static async get() {
    // debugger
    console.log('Firebase Apps Count =', firebase.apps.length);
    console.log('Current User =', firebase.auth().currentUser);

    if (firebase.apps.length && firebase.auth().currentUser) {
      return await firebase.auth().currentUser.getIdToken(true);
    }
    return null;
  }
}
