<template>
  <button
    :type="type"
    :autofocus="autofocus"
    :disabled="disabled"
    @click="$emit('on-click')"
  >
    <span v-if="!loading" v-html="label"></span>
    <slot v-if="!loading"></slot>
    <slot name="loading" v-if="loading">
      <!-- <span>{{ i18n('common.loading') }}</span> -->
      <LoadingIcon icon="tail-spin" color="#FFFFFF" style="margin: 0 4px;" />
    </slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    label: {
      type: String || Number
    },
    icon: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style>
</style>
