/* eslint-disable */
import { i18n } from '@/locales/i18n';
import vueI18n from '@/vueI18n';
import Toastify from 'toastify-js'


// node: cash('#success-notification-content').clone().removeClass('hidden')[0] // notificationToggle
// node: cash('#error-notification-content').clone().removeClass('hidden')[0] // errorNotificationToggle
// node: cash('#success-notification-with-buttons').clone().removeClass('hidden')[0] // successNotificationToggle
// onClick: () => {
//   this.$router.push('/category')
//   document.getElementById('success-notification-with-buttons').remove()
// }

// i18n.locale == 'ar' ? 'left' : 'right'

export default class Message {
  static success(payload) {
    const language = vueI18n.global.locale
    const element = cash('#success-notify-content').clone().removeClass('hidden')[0]
    element.innerHTML = element.innerHTML.replace('--text--', payload)

    Toastify({
      node: element,
      text: payload,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top",    // `top` or `bottom`
      position: language == 'ar' ? 'left' : 'right', // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      // destination: "https://github.com/apvarun/toastify-js",
      // avatar: 'https://static.vecteezy.com/system/resources/previews/002/743/514/original/green-check-mark-icon-in-a-circle-free-vector.jpg',
      // className: 'toastify-content',
      // style: {
      //   background: "linear-gradient(to right, #00b09b, #96c93d)",
      // },
      onClick: () => { // Callback after click
        // this.$router.push('/category')
        // document.getElementById('success-notification-with-buttons').remove()
      }
    }).showToast();
  }

  static error(payload) {
    let message = payload;

    if (!message) {
      message = i18n('errors.defaultErrorMessage');
    }

    const language = vueI18n.global.locale
    const element = cash('#error-notify-content').clone().removeClass('hidden')[0]
    element.innerHTML = element.innerHTML.replace('--text--', payload)

    Toastify({
      node: element,
      text: payload,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top",    // `top` or `bottom`
      position: language == 'ar' ? 'left' : 'right', // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      // destination: "https://github.com/apvarun/toastify-js",
      // avatar: 'https://icon-library.com/images/error-image-icon/error-image-icon-23.jpg',
      // className: 'toastify-content',
      // style: {
      //   background: "linear-gradient(to right, #00b09b, #96c93d)",
      // },
      onClick: () => { // Callback after click
        // this.$router.push('/category')
        // document.getElementById('success-notification-with-buttons').remove()
      }
    }).showToast();
  }

  static warning(payload) {
    let message = payload;

    if (!message) {
      message = i18n('errors.defaultErrorMessage');
    }
    Notify.create({
      message: payload,
      type:"warning",
      color: 'white',
      textColor: 'warning',
      timeout: 3000,
      position:'center',
      // progress: true,
      // icon:'fas fa-exclamation-triangle',
      multiLine: true,
      actions:[
        { label: 'Dismiss', color: 'cyan', handler: () => {} }
      ]
    })
  }
}
